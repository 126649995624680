<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Chương trình thưởng hoa hông AdHoc'">
          <template v-if="mainModel.id" v-slot:toolbar>
            <div class="row">
              <div class="ml-3">
                <router-link
                  :to="`/reward/history-reward-adhoc?rewardAdhocId=${mainModel.id}`"
                >
                  <button
                    type="button"
                    class="btn btn-secondary font-weight-bolder btn-sm pt-3"
                  >
                    <i class="fas fa-history"></i>
                    Lịch sử chỉnh sửa
                  </button>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          id="input-group-name"
                          label="Tên chương trình"
                          label-for="input-name"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-input
                            size="sm"
                            id="input-name"
                            v-model="$v.mainModel.name.$model"
                            placeholder="Nhập tên"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Mô tả">
                          <b-form-textarea
                            id="input-description"
                            v-model="mainModel.description"
                            placeholder="Nhập mô tả"
                            rows="5"
                            :max-length="255"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group class="required-control">
                          <label>Từ ngày:</label>
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.startDate"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label>Đến ngày</label>
                          <date-picker
                            placeholder="Đến ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.endDate"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <h4>Danh sách sản phẩm/ nhóm sản phẩm</h4>
                <div class="separator separator-dashed my-5"></div>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm/ nhóm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="onChangeTypeSearch"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeOfProduct"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          @selected="onSelectedProductFor"
                          :limit="10"
                          @input="onInputChangeProductFor"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.name }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        id="my-table-main"
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="mainModel.details"
                        v-if="renderComponent"
                        :per-page="10"
                        :current-page="currentPageMain"
                      >
                        <template v-slot:cell(type)="row">
                          <span v-if="row.item.type === TYPE_CONST.PRODUCT"
                            >Sản phẩm</span
                          >
                          <span v-if="row.item.type === TYPE_CONST.CATEGORY"
                            >Nhóm sản phẩm</span
                          >
                        </template>
                        <template v-slot:cell(actions)="row">
                          <div class="d-flex justify-content-center">
                            <v-icon
                              small
                              class="text-danger"
                              @click="showDeleteAlert(row.item)"
                              v-b-tooltip
                              title="Xóa"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </template>
                        <template v-slot:cell(areaBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.areaBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(managerBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.managerBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(assistantBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.assistantBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(saleBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.saleBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(technicalBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.technicalBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(cashierBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.cashierBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(customerReceptionBonus)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.customerReceptionBonus"
                            v-mask="mask"
                          ></b-input>
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="mainModel.details.length > 10"
                        v-model="currentPageMain"
                        :total-rows="rowsMain"
                        :per-page="10"
                        aria-controls="my-table-main"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm/ nhóm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="xl"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a
                        class="cursor-pointer"
                        @click="onDownloadTemplateImport()"
                        >đây</a
                      >
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(type)="row">
                    <span v-if="row.item.type === TYPE_CONST.PRODUCT"
                      >Sản phẩm</span
                    >
                    <span v-if="row.item.type === TYPE_CONST.CATEGORY"
                      >Nhóm sản phẩm</span
                    >
                  </template>
                  <template v-slot:cell(areaBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.areaBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.areaBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(managerBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.managerBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.managerBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(assistantBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.assistantBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.assistantBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(saleBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.saleBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.saleBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(technicalBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.technicalBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.technicalBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(cashierBonus)="row">
                    <span
                      v-if="!isNaN(formatPrice(row.item.cashierBonus))"
                      class="text-right"
                      >{{ formatPrice(row.item.cashierBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(customerReceptionBonus)="row">
                    <span
                      v-if="
                        !isNaN(formatPrice(row.item.customerReceptionBonus))
                      "
                      class="text-right"
                      >{{ formatPrice(row.item.customerReceptionBonus) }}</span
                    >
                    <span v-else class="text-danger">Sai dữ liệu</span>
                  </template>
                  <template v-slot:cell(isValid)="row">
                    <span v-if="row.item.isValid === true" class="text-success"
                      >Đúng</span
                    >
                    <span v-if="row.item.isValid === false" class="text-danger"
                      >Sai mã</span
                    >
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                  align="right"
                ></b-pagination>
              </b-container>
              <b-button
                v-if="!isValidDataImport"
                style="fontweight: 600"
                variant="primary"
                size="sm"
                @click="checkDataImportProduct"
                :disabled="importItems.length === 0"
                >Kiểm tra dữ liệu</b-button
              >
              <b-button
                v-else
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="saveDataImportProduct"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
          </template>
          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >{{ mainModel.id ? 'Cập nhật' : 'Thêm mới' }}</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import Swal from 'sweetalert2';
import _ from 'lodash';
import decounce from 'debounce';
import xlsx from 'xlsx';
import moment from 'moment';
import axios from 'axios';
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import {
  BASE_URL,
  TIME_TRIGGER,
  URL_IMPORT_REWARD_PRODUCT_CATEGORY,
} from './../../../utils/constants';
import {
  currencyMask,
  formatPrice,
  unMaskMoney,
} from './../../../utils/common';
import { PRODUCT_SEARCH_TYPE } from '@/utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      selectedCategory: [],
      selectedStores: [],
      isValidDataImport: false,
      dataImportProducts: [],
      TYPE_CONST: {
        PRODUCT: 1,
        CATEGORY: 2,
      },
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mainModel: {
        id: 0,
        name: null,
        startDate: null,
        endDate: null,
        description: null,
        details: [],
        type: 1,
      },
      fields: [
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'areaBonus',
          label: 'Thưởng QL Khu vực',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'managerBonus',
          label: 'Thưởng QL Cửa hàng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'assistantBonus',
          label: 'Thưởng TL Cửa hàng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'saleBonus',
          label: 'Thưởng NV Bán hàng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'technicalBonus',
          label: 'Thưởng NV Kỹ thuật',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'cashierBonus',
          label: 'Thưởng thu ngân',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'customerReceptionBonus',
          label: 'Thưởng tiếp đón KH',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '3%',
          },
        },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProductAccessory: '',
      accessoryItems: [],
      accessoryProductItems: [],
      searchProductFor: '',
      categories: '',
      stores: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      listPriority: [
        {
          id: '1',
          name: 'Số 1 (cao nhất)',
        },
        {
          id: '2',
          name: 'Số 2',
        },
        {
          id: '3',
          name: 'Số 3',
        },
        {
          id: '4',
          name: 'Số 4',
        },
        {
          id: '5',
          name: 'Số 5',
        },
        {
          id: '6',
          name: 'Số 6',
        },
        {
          id: '7',
          name: 'Số 7',
        },
        {
          id: '8',
          name: 'Số 8',
        },
        {
          id: '9',
          name: 'Số 9',
        },
        {
          id: '10',
          name: 'Số 10',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
        {
          id: 3,
          name: 'Nhóm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      isSearching: false,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'type',
          label: 'Loại',
        },
        {
          key: 'code',
          label: 'Mã',
        },
        {
          key: 'name',
          label: 'Tên',
        },
        {
          key: 'areaBonus',
          label: 'Thưởng QL Khu vực',
        },
        {
          key: 'managerBonus',
          label: 'Thưởng QL Cửa hàng',
        },
        {
          key: 'assistantBonus',
          label: 'Thưởng TL Cửa hàng',
        },
        {
          key: 'saleBonus',
          label: 'Thưởng NV Bán hàng',
        },
        {
          key: 'technicalBonus',
          label: 'Thưởng NV Kỹ thuật',
        },
        {
          key: 'cashierBonus',
          label: 'Thưởng thu ngân',
        },
        {
          key: 'customerReceptionBonus',
          label: 'Thưởng tiếp đón KH',
        },
        {
          key: 'isValid',
          label: 'Kiểm tra',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_REWARD_PRODUCT_CATEGORY,
      currentPageMain: 1,
      listType: [
        {
          id: 1,
          name: 'Theo doanh thu',
        },
        {
          id: 2,
          name: 'Theo công',
        },
      ],
    };
  },
  validations: {
    mainModel: {
      name: {
        required,
      },
    },
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsMain() {
      return this.mainModel.details.length;
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thường hoa hồng AdHoc',
        route: '/reward/reward-adhoc',
      },
      {
        title: `${
          this.mainModel.id ? 'Cập nhật' : 'Thêm mới'
        } chương trình thường hoa hồng AdHoc`,
      },
    ]);
    this.$refs['import-product-modal'].$on('hidden', () => {
      this.isValidDataImport = false;
    });
  },
  created() {
    this.fetchStore();
    if (this.$route.query.id) {
      this.mainModel.id = this.$route.query.id;
      this.fetchData();
    }
  },
  methods: {
    formatPrice,
    ...getToastInstance(),
    mask: currencyMask,
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    fetchData() {
      ApiService.setHeader();
      ApiService.get(`reward-adhoc/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel = data.data;
          this.mainModel.startDate = moment(data.data.startDate).format(
            'DD/MM/YYYY',
          );
          this.mainModel.endDate = moment(data.data.endDate).format(
            'DD/MM/YYYY',
          );
        })
        .catch(({ response }) => {
          this.makeToastFailed(response.$error);
        });
    },
    getCategories() {
      ApiService.setHeader();
      ApiService.query('category')
        .then(({ data }) => {
          if (data.status === 1) {
            this.categories = data.data.list_caterogy;
          } else {
            this.makeToastFailed('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailed(response.$error);
        });
    },
    fetchStore: async function () {
      this.stores = [];
      ApiService.get(`stores?limit=1000&page=1`).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.stores.push(store);
        });
      });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm/ nhóm sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm/ nhóm sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.referenceId == item.referenceId;
            },
          );
        }
      });
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-product-category?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProductFor[0].data.push(element);
        });
        this.filteredOptionsProductFor = [
          {
            data: this.optionsProductFor[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProductFor(nextTextSearch);
        }
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      let type = PRODUCT_SEARCH_TYPE.PRODUCT_NAME;
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO) {
        type = PRODUCT_SEARCH_TYPE.IMEI_CODE;
      } else if (this.selectTypeSearch === 4) {
        type = PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO;
      }
      let item = {
        referenceId: option.item.id,
        name: option.item.name,
        code: option.item.code,
        type: type,
        areaBonus: 0,
        managerBonus: 0,
        assistantBonus: 0,
        saleBonus: 0,
        technicalBonus: 0,
        cashierBonus: 0,
        customerReceptionBonus: 0,
      };
      this.mainModel.details.push(item);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    makeToastFailed(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onBack: function () {
      history.back();
    },
    onSubmit: function () {
      if (!this.mainModel.name) {
        this.makeToastWarning('Vui lòng nhập tên chương trình!');
        return;
      }

      if (!this.mainModel.startDate) {
        this.makeToastWarning('Vui lòng chọn ngày bắt đầu!');
        return;
      }

      const formattedStartDate = moment(
        this.mainModel.startDate,
        'DD/MM/YYYY',
      ).toDate();
      const formattedEndDate = moment(
        this.mainModel.endDate,
        'DD/MM/YYYY',
      ).toDate();

      const details = this.mainModel.details.map((item) => {
        return {
          type: item.type,
          referenceId: item.referenceId,
          areaBonus: Number(unMaskMoney(item.areaBonus)),
          managerBonus: Number(unMaskMoney(item.managerBonus)),
          assistantBonus: Number(unMaskMoney(item.assistantBonus)),
          saleBonus: Number(unMaskMoney(item.saleBonus)),
          technicalBonus: Number(unMaskMoney(item.technicalBonus)),
          cashierBonus: Number(unMaskMoney(item.cashierBonus)),
          customerReceptionBonus: Number(
            unMaskMoney(item.customerReceptionBonus),
          ),
        };
      });

      const payload = {
        name: this.mainModel.name,
        type: this.mainModel.type,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        description: this.mainModel.description,
        details: details,
      };

      if (this.mainModel.id) {
        return ApiService.put(`reward-adhoc/${this.mainModel.id}`, payload)
          .then((response) => {
            const { message } = response.data;
            this.makeToastSuccess(message);
            return this.$router.push({
              name: 'reward-adhoc',
            });
          })
          .catch((err) => {
            const message = err.response.data.message;
            return this.makeToastFailure(message);
          });
      }

      return ApiService.post('reward-adhoc', payload)
        .then((response) => {
          const { message } = response.data;
          this.makeToastSuccess(message);
          return this.$router.push({
            name: 'reward-adhoc',
          });
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          });
          const excellist = [];
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.excellist = excellist;
          this.excellist.map((element) => {
            const data = {
              name: element['Tên'] || '',
              code: element['Mã'] || '',
              type:
                element['Loại'] === 'Nhóm sản phẩm'
                  ? this.TYPE_CONST.CATEGORY
                  : this.TYPE_CONST.PRODUCT,
              areaBonus: element['Thưởng QL Khu vực'] || '',
              managerBonus: element['Thưởng QL Cửa hàng'] || '',
              assistantBonus: element['Thưởng TL Cửa hàng'] || '',
              saleBonus: element['Thưởng NV Bán hàng'] || '',
              technicalBonus: element['Thưởng NV Kỹ thuật'] || '',
              cashierBonus: element['Thưởng thu ngân'] || '',
              customerReceptionBonus: element['Thưởng tiếp đón KH'] || '',
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    saveDataImportProduct() {
      const existingDetails = new Set(
        this.mainModel.details.map((item) => `${item.type}-${item.code}`),
      );

      this.dataImportProducts.forEach((element) => {
        const key = `${element.type}-${element.code}`;
        if (!existingDetails.has(key)) {
          this.mainModel.details.push(element);
          existingDetails.add(key);
        }
      });

      return this.hideModalImportProduct();
    },
    checkDataImportProduct() {
      if (!this.isValidAmount(this.importItems)) {
        return this.makeToastFailure(
          'Dữ liệu chưa đúng! Tiền thưởng phải là số hợp lệ.',
        );
      }

      ApiService.setHeader();
      ApiService.post('reward-adhoc/validate-import-excels', {
        data: this.importItems,
      })
        .then((response) => {
          const { isValid, data } = response.data.data;

          this.importItems = data;
          console.log(this.importItems);

          if (!isValid) {
            return this.makeToastFailure('Dữ liệu chưa đúng!');
          }

          this.isValidDataImport = true;
          this.dataImportProducts = data;
          return this.makeToastSuccess('Dữ liệu chính xác!');
        })
        .catch((err) => {
          const message = err;
          return this.makeToastFailure(message);
        });
    },
    isValidAmount(importItems) {
      return importItems.every((item) => {
        return Object.keys(item).every((key) => {
          if (key.endsWith('Bonus')) {
            const value = Number(item[key]);
            return !isNaN(value) && value > 0;
          }
          return true;
        });
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onChangeTypeSearch() {
      this.selectTypeOfProduct = 0;
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO) {
        this.listTypeOfProduct = [
          {
            id: 0,
            name: 'Tất cả nhóm sản phẩm',
          },
          {
            id: 1,
            name: 'Nhóm sản phẩm cha',
          },
          {
            id: 2,
            name: 'Nhóm sản phẩm con',
          },
        ];
      } else {
        this.listTypeOfProduct = [
          {
            id: 0,
            name: 'Tất cả sản phẩm',
          },
          {
            id: 1,
            name: 'Sản phẩm cha',
          },
          {
            id: 2,
            name: 'Sản phẩm con',
          },
          {
            id: 3,
            name: 'Sản phẩm độc lập',
          },
          {
            id: 4,
            name: 'Sản phẩm cha + độc lập',
          },
          {
            id: 5,
            name: 'Sản phẩm con + độc lập',
          },
        ];
      }
      this.debounceInputFor();
    },
    onDownloadTemplateImport: async function () {
      const url = `${BASE_URL}reward-adhoc/template-import-excels`;

      await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
      }).then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'import-san-pham-hoa-hong-adhoc.xlsx';

        if (contentDisposition && contentDisposition.includes('filename=')) {
          filename = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, '');
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
};
</script>

<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
